import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API)

const createWhiteLabel = (accountingFirmId, theme, data) => {
	return axios.post(URLS.api.sa.accountingFirms.whiteLabels.uri(accountingFirmId, theme), data)
}

const listWhiteLabels = accountingFirmId => {
	return axios.get(URLS.api.sa.accountingFirms.whiteLabels.uri(accountingFirmId))
}

const updateWhiteLabel = (accountingFirmId, theme, data) => {
	data.append('_method', 'PATCH')
	return axios.post(URLS.api.sa.accountingFirms.whiteLabels.uri(accountingFirmId, theme), data)
}

const createAccountingFirm = (data, config = {}) => {
	return axios.post(URLS.api.sa.accountingFirms.uri(), data, config)
}

const deleteAccountingFirm = (accountingFirmId, params = {}) => {
	return axios.delete(URLS.api.sa.accountingFirms.uri(accountingFirmId), { data: params })
}

const getAccountingFirm = accountingFirmId => {
	return axios.get(URLS.api.sa.accountingFirms.uri(accountingFirmId))
}

const getAccountingFirms = data => {
	return axios.get(URLS.api.sa.accountingFirms.uri(), data)
}

const listAccountingFirms = query => {
	return axios.get(URLS.api.sa.accountingFirms.uri(), {
		params: query
	})
}

const restoreAccountingFirm = accountingFirmId => {
	return axios.post(URLS.api.sa.accountingFirms.restore.uri(accountingFirmId))
}

const updateAccountingFirm = (accountingFirmId, data, config = {}) => {
	return axios.post(URLS.api.sa.accountingFirms.uri(accountingFirmId), data, config)
}

const createApplication = data => {
	return axios.post(URLS.api.sa.applications.uri(), data)
}

const getApplication = applicationId => {
	return axios.get(URLS.api.sa.applications.uri(applicationId))
}

const listApplications = (query = {}) => {
	return axios.get(URLS.api.sa.applications.uri(), {
		params: query
	})
}

const updateApplication = (applicationId, data) => {
	data.append('_method', 'PATCH')
	return axios.post(URLS.api.sa.applications.uri(applicationId), data)
}

const deleteApplication = applicationId => {
	return axios.delete(URLS.api.sa.applications.uri(applicationId))
}

const createAccountingFirmApplication = (accountingFirmId, applicationId) => {
	return axios.post(URLS.api.sa.accountingFirms.applications.uri(accountingFirmId, applicationId))
}

const deleteAccountingFirmApplication = (accountingFirmId, applicationId) => {
	return axios.delete(URLS.api.sa.accountingFirms.applications.uri(accountingFirmId, applicationId))
}

const createCatalogTheme = data => {
	return axios.post(URLS.api.sa.catalogTheme.uri(), data)
}

const deleteCatalogTheme = catalogThemeId => {
	return axios.delete(URLS.api.sa.catalogTheme.uri(catalogThemeId))
}

const listCatalogThemes = (params) => {
	return axios.get(URLS.api.sa.catalogTheme.uri(), { params })
}

const updateCatalogTheme = (catalogThemeId, data) => {
	return axios.patch(URLS.api.sa.catalogTheme.uri(catalogThemeId), data)
}

const getCatalogTreeStructures = () => {
	return axios.get(URLS.api.sa.catalogTreeStructure.uri())
}

const createCustomers = (accountingFirmId, file, config) => {
	const data = new FormData()
	data.append('import_file', file)
	return axios.post(URLS.api.sa.customers.uri(accountingFirmId), data, config)
}

const createUsers = (accountingFirmId, file, config) => {
	const data = new FormData()
	data.append('import_file', file)
	return axios.post(URLS.api.sa.users.uri(accountingFirmId), data, config)
}

const createFlag = data => {
	return axios.post(URLS.api.sa.catalogFlags.uri(), data)
}

const deleteFlag = flagId => {
	return axios.delete(URLS.api.sa.catalogFlags.uri(flagId))
}

const getFlags = () => {
	return axios.get(URLS.api.sa.catalogFlags.uri())
}

const updateFlag = (flagId, data) => {
	return axios.patch(URLS.api.sa.catalogFlags.uri(flagId), data)
}

const listHoldings = () => {
	return axios.get(URLS.api.sa.holdings.uri())
}

const getAccountingFirmModules = () => {
	return axios.get(URLS.api.sa.accountingFirms.modules.uri())
}

const getModules = () => {
	return axios.get(URLS.api.sa.modules.uri())
}

const getModulesSubscriptions = () => {
	return axios.get(URLS.api.sa.modules.subscriptions.uri())
}

const subscribeToModule = (accountingFirmId, moduleId, customParameters) => {
	let params = {}
	if (customParameters !== undefined && customParameters !== null) {
		params.customParameters = customParameters
	}
	return axios.patch(URLS.api.sa.modules.subscribe.uri(accountingFirmId, moduleId), params)
}

const unsubscribeToModule = (accountingFirmId, moduleId) => {
	return axios.patch(URLS.api.sa.modules.unsubscribe.uri(accountingFirmId, moduleId))
}

const updateModule = (moduleId, data) => {
	return axios.patch(URLS.api.sa.modules.uri(moduleId), data)
}

const getMobileApp = mobileAppId => {
	return axios.get(URLS.api.sa.mobileApps.uri(mobileAppId))
}

const listMobileApp = (reset = false) => {
	const params = reset ? { params: { reset: true } } : null
	return axios.get(URLS.api.sa.mobileApps.uri(), params)
}

const createMobileApp = data => {
	return axios.post(URLS.api.sa.mobileApps.uri(), data)
}

const createMobileAppWizard = data => {
	return axios.post(URLS.api.sa.mobileAppsWizard.uri(), data)
}

const updateMobileApp = (mobileAppId = null, formdata) => {
	formdata.append('_method', 'PATCH')
	return axios.post(URLS.api.sa.mobileApps.uri(mobileAppId), formdata)
}

const updateMobileAppWizard = (mobileAppId, data) => {
	return axios.patch(URLS.api.sa.mobileAppsWizard.uri(mobileAppId), data)
}

const deleteMobileApp = mobileAppId => {
	return axios.delete(URLS.api.sa.mobileApps.uri(mobileAppId))
}

const deleteMobileAppWizard = mobileAppId => {
	return axios.delete(URLS.api.sa.mobileAppsWizard.uri(mobileAppId))
}

const getAccountingFirmList = () => {
	return axios.get(URLS.api.sa.mobileApps.accountingfirms.uri())
}

const getNPSSurveySettings = () => {
	return axios.get(URLS.api.sa.npsSurveys.uri())
}

const updateNPSSurveySettings = data => {
	return axios.patch(URLS.api.sa.npsSurveys.uri(), data)
}

const getAutomationTypes = () => {
	return axios.get(URLS.api.sa.applications.automations.types.uri())
}

const executeCommand = (data) => {
	return axios.post(URLS.api.sa.support.uri(), data)
}

const getTestCases = () => {
	return axios.post(URLS.api.sa.catalogTreeStructureTestCases.uri())
}

const checkDomain = (domain) => {
	return axios.get(URLS.api.sa.accountingFirms.domains.uri(domain))
}

export default {
	createWhiteLabel,
	listWhiteLabels,
	updateWhiteLabel,
	createAccountingFirm: createAccountingFirm,
	deleteAccountingFirm: deleteAccountingFirm,
	getAccountingFirm: getAccountingFirm,
	getAccountingFirms: getAccountingFirms,
	listAccountingFirms,
	restoreAccountingFirm: restoreAccountingFirm,
	updateAccountingFirm: updateAccountingFirm,
	createApplication: createApplication,
	deleteApplication: deleteApplication,
	getApplication,
	listApplications,
	updateApplication: updateApplication,
	createAccountingFirmApplication,
	deleteAccountingFirmApplication,
	createCatalogTheme: createCatalogTheme,
	deleteCatalogTheme: deleteCatalogTheme,
	listCatalogThemes,
	updateCatalogTheme: updateCatalogTheme,
	getCatalogTreeStructures: getCatalogTreeStructures,
	createCustomers: createCustomers,
	createUsers: createUsers,
	createFlag: createFlag,
	deleteFlag: deleteFlag,
	getFlags: getFlags,
	updateFlag: updateFlag,
	listHoldings,
	getAccountingFirmModules: getAccountingFirmModules,
	getModules: getModules,
	getModulesSubscriptions: getModulesSubscriptions,
	subscribeToModule: subscribeToModule,
	unsubscribeToModule: unsubscribeToModule,
	updateModule: updateModule,
	getMobileApp: getMobileApp,
	listMobileApp: listMobileApp,
	createMobileApp: createMobileApp,
	updateMobileApp: updateMobileApp,
	deleteMobileApp: deleteMobileApp,
	createMobileAppWizard: createMobileAppWizard,
	deleteMobileAppWizard: deleteMobileAppWizard,
	updateMobileAppWizard: updateMobileAppWizard,
	getNPSSurveySettings: getNPSSurveySettings,
	updateNPSSurveySettings: updateNPSSurveySettings,
	getAccountingFirmList: getAccountingFirmList,
	getAutomationTypes: getAutomationTypes,
	executeCommand: executeCommand,
	getTestCases: getTestCases,
	checkDomain: checkDomain
}
